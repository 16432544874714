import React from "react";
import "./PortfolioSection.css";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import underlinePortfolioImage from "../assets/images/underline-portfolio.svg";
import Slider from "../Components/Common/Slider/Slider";
import { PortfolioList } from "../Portfolio/PortfolioList";

const PortfolioSection = () => {
  return (
    <div className="slider-portfolio-wrapper-main" id="portfolio-section">
      <section className="section container-default pd-top-bottom-200px portfolio-section-home">
        <div
          className="portfolio-section-header container-default-child"
          data-aos="fade"
        >
          <h2>
            Take a look at my
            <br />
            design portfolio
          </h2>
          <img
            alt="na"
            className="image portfolio-underline"
            src={underlinePortfolioImage}
            loading="lazy"
          />
        </div>
        <div data-aos="fade">
          <Slider>
            {Object.keys(PortfolioList).map((item, index) => (
              <PortfolioCard
                key={index}
                title={PortfolioList[item].title}
                subtitle={PortfolioList[item].subtitle}
                link={item}
                status={PortfolioList[item].link}
                image={PortfolioList[item].heroImage}
              />
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};
export default PortfolioSection;
