import React from "react";
import { GoArrowUpRight } from "react-icons/go";
import "./Card.css";

const Card = (props) => {
  const { title, subtitle, date, companyName, icon, aos, link } = props;
  return (
    <div className="card-container" data-aos={aos}>
      {!companyName && <img alt="na" className="image card-icon" src={icon} />}
      <div>
        {companyName && (
          <div className="company-wrapper">
            <a href={link} target="_blank">
              <img alt="na" className="image card-company-logo" src={icon} />
              <GoArrowUpRight className="top-right-icon" />
            </a>
          </div>
        )}
        <div className="card-flex-title">
          <h3 className="title card-title">{title}</h3>
          {date && <div>{date}</div>}
        </div>
        <p
          className="paragraph card-paragraph"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
      </div>
    </div>
  );
};

export default Card;
