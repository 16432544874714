import React from "react";
import "./AboutPage.css";
import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import AboutSection from "../AboutSection/AboutSection";

const AboutPage = () => {
  return (
    <>
      <Header />
      <section className="about-page-section">
        <AboutSection
          title={"I'm a Flautist, Artist & Poet, when not working"}
          subtitle={"Exploring creativity through poetry, music, and art, I find joy and expression in these passions. Each moment spent with words, melodies, and colors enriches and fuels my imagination."}
          page={true}
        />
      </section>
      <FooterSection />
    </>
  );
};

export default AboutPage;
