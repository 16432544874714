import devTonics from "../assets/images/projects/hero-devtonics-main.png";
import expenseTracker from "../assets/images/projects/hero-expense-tracker.png";
import resumeBuilder from "../assets/images/projects/hero-resume-builder.png";
import timeTableManager from "../assets/images/projects/hero-time-table-manager.png";
import zinia from "../assets/images/projects/hero-zinnia.png";
import ignite from "../assets/images/projects/hero-ignite-educational-service.png";
import pinnacle from "../assets/images/projects/hero-pinnacle-security-shield.png";
import placementBot from "../assets/images/projects/hero-placement-bot.png";
import todoNotes from "../assets/images/projects/hero-todo-notes-app.png";
import certGenerator from "../assets/images/projects/hero-certificate-generator.png";
import hospitalManagement from "../assets/images/projects/hero-hospital-management.png";
import schoolManagement from "../assets/images/projects/hero-school-management.png";
import typescriptQuiz from "../assets/images/projects/hero-typescript-quiz.png";
import carPrice from "../assets/images/projects/hero-car-price-predictor.png";
import studentLearner from "../assets/images/projects/hero-student-learner.png";
import newsSentiment from "../assets/images/projects/hero-news-sentiment.png";
import courierManager from "../assets/images/projects/hero-courier-manager.png";
import inventoryManager from "../assets/images/projects/hero-inventory-manager.png";

import shardaKendra from "../assets/images/projects/hero-sharda-kendra.png";
import strengthBricks from "../assets/images/projects/hero-strength-bricks.png";
import motionPlus from "../assets/images/projects/hero-motion-plus.png";
import tedxLpu from "../assets/images/projects/hero-tedx-lpu.png";
import avneetKaur from "../assets/images/projects/hero-avneet-kaur-1.png";
import recipeSearch from "../assets/images/projects/hero-recipe-search.png";

import expenseTrackerOverview from "../assets/images/projects/hero-expense-tracker-overview.png";
import resumeBuilderOverview from "../assets/images/projects/hero-resume-builder-overview.png";
import timeTableManagerOverview from "../assets/images/projects/hero-time-table-manager-overview.png";
import ziniaOverview from "../assets/images/projects/hero-zinnia-overview.png";
import igniteOverview from "../assets/images/projects/hero-ignite-educational-service-overview.png";
import pinnacleOverview from "../assets/images/projects/hero-pinnacle-security-shield-overview.png";
import placementBotOverview from "../assets/images/projects/hero-placement-bot-overview.png";
import todoNotesOverview from "../assets/images/projects/hero-todo-notes-app-overview.png";
import certGeneratorOverview from "../assets/images/projects/hero-certificate-generator-overview.png";
import hospitalManagementOverview from "../assets/images/projects/hero-hospital-management-overview.png";
import schoolManagementOverview from "../assets/images/projects/hero-school-management-overview.png";
import carPriceOverview from "../assets/images/projects/hero-car-price-predictor-overview.png";
import studentLearnerOverview from "../assets/images/projects/hero-student-learner-overview.png";
import newsSentimentOverview from "../assets/images/projects/hero-news-sentiment-overview.png";
import inventoryManagerOverview from "../assets/images/projects/hero-inventory-manager-overview.png";
import shardaKendraOverview from "../assets/images/projects/hero-sharda-kendra-overview.png";
import strengthBricksOverview from "../assets/images/projects/hero-strength-bricks-overview.png";
import motionPlusOverview from "../assets/images/projects/hero-motion-plus-overview.png";
import tedxLpuOverview from "../assets/images/projects/hero-tedx-lpu-overview.png";
import avneetKaurOverview from "../assets/images/projects/hero-avneet-kaur.png";
import recipeSearchOverview from "../assets/images/projects/hero-recipe-search-overview.png";
import devTonicsOverview from "../assets/images/projects/hero-devtonics.png";

// J:\SSD Partition 2\repo\xampp-both-extension\xampp1\htdocs\ak

export const PortfolioList = {
  "devtonics": {
    title: "Dev Tonics",
    subtitle:
      "Exploring the intersection of web/ app dev, cybersecurity & DevOps one at a time! 🚀",
    pageTitle: "Dev Tonics",
    type: "Personal",
    date: "Nov 2023",
    link: {'DevTonics': 'https://devtonics.in', 'Forum': 'https://forum.devtonics.in', 'Instagram': 'https://instagram.com/DevTonics', 'LinkedIn': 'https://www.linkedin.com/company/DevTonics'},
    github: "#",
    expertise: "Next, Express, MySQL",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: devTonics,

    overview:
      "DevTonics is a dynamic blog delving into the convergence of web and app development, cybersecurity, and DevOps. With a focus on one area at a time, it offers insightful articles, tutorials, and expert tips. Whether you're a developer, security enthusiast, or DevOps professional, DevTonics provides valuable knowledge to enhance your skills and keep you updated on the latest industry trends.",
    overviewImage: devTonicsOverview,
    execution: `<strong> Key features:</strong>
    <ul>
      <li>
        Latest Technical Trends: <br />
        Get updates on the newest developments and innovations in the tech world, helping you stay ahead of the curve.
      </li>
      <li>
        Expert Insights and Learning: <br />
        Gain valuable knowledge through tutorials, expert tips, and in-depth analyses designed to enhance your skills and understanding.
      </li>
    </ul>
    `,
  },
  "expense-tracker": {
    title: "Expense Tracker Mobile App",
    subtitle:
      "Effortlessly monitor spending, and achieve financial control on-the-go. Auto track transactions through text messages.",
    pageTitle: "Expense Tracker Mobile App",
    type: "Personal",
    date: "Aug 2023",
    link: "https://play.google.com/store/apps/details?id=com.quantumfusionlabs.expensetracker",
    github: "#",

    expertise: "React Native, Redux, TypeScript, Django, MySQL, GCP",
    platforms: "iOS and Android",
    deliverables: "UI Screens, Backend",
    heroImage: expenseTracker,

    overview:
      "Effortlessly monitor spending, and achieve financial control on-the-go. Auto track transactions through text messages. Stay on top of your spending with intuitive features designed to make budgeting effortless. The app provides a user-friendly interface that allows you to effortlessly log and categorize your expenses on the go. From daily coffee runs to monthly bills, easily track every penny with just a few taps.",
    overviewImage: expenseTrackerOverview,
    execution: `<strong> Key features:</strong>
    <ul>
      <li>
        Smart Categorization<br />
        Automatically categorize transactions based on spending patterns, saving
        time and ensuring accurate financial insights.
      </li>
      <li>
        Visual Reports and Insights: <br />
        Gain a clear understanding of financial health with visual reports and
        insights. Track trends, analyze spending patterns, and make informed
        decisions to improve financial well-being.
      </li>
      <li>
        Sync Across Devices:<br />
        Seamlessly sync data across multiple devices, to manage finances.
      </li>
    </ul>
    `,
  },
  "resume-builder": {
    title: "Resume Builder Web App",
    subtitle:
      "Helped <strong><i>10K+ users</i></strong> across the globe craft a professional resume with 9 different templates available free of cost. ",
    pageTitle: "Resume Builder Web App",
    type: "Personal",
    date: "Nov 2021",
    link: { "makemycv.devtonics.in (v1)": "https://makemycv.devtonics.in" },
    github: "#",

    expertise: "React, Redux, TypeScript, Express JS, MySQL, AWS (EC2, RDBMS)",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: resumeBuilder,

    overview:
      "Helped <strong><i>10K+ users</i></strong> across the globe craft a professional resume with 9 different templates available free of cost. ",
    overviewImage: resumeBuilderOverview,
    execution: `<strong> Key features:</strong>
    <ul>
      <li>
        Intuitive Resume Creation:<br />
        User friendly interface guides users through the resume-building process, ensuring a seamless and stress-free experience. 
      </li>
      <li>
        Customizable Templates: <br />
        Stand out from the crowd with professionally designed templates that can be easily customized to reflect your unique skills and personality. Choose from a variety of layouts to make a lasting impression.
      </li>
      <li>
        Real-time Previews:<br />
        Preview your resume in real-time as you make edits, allowing you to see the final result instantly. This feature ensures that you have complete control over the look and feel of your resume.
      </li>
    </ul>
    `,
  },
  "todo-notes-taking-mobile-app": {
    title: "Todo & Notes Taking Mobile App",
    subtitle:
      "Provides a way to track tasks with the percentage/ fraction of work done. Users can save notes/ secrets and add a PIN based lock.",
    pageTitle: "Todo & Notes Taking Mobile App",
    type: "Personal",
    date: "June 2019",
    link: "Retired",
    github: "#",

    expertise: "React Native, Redux, SQLite",
    platforms: "iOS and Android",
    deliverables: "UI Screens, DB",
    heroImage: todoNotes,

    overview:
      "Todo & Notes Taking Mobile App designed with a unique emphasis on security and organization. This app serves as a comprehensive solution for managing tasks, jotting down notes, and safeguarding sensitive information. Provides a way to track tasks with the percentage/ fraction of work done. Users can save notes/ secrets and add a PIN based lock.",
    overviewImage: todoNotesOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Efficient Task Management:<br />
        The Todo & Notes Taking Mobile App streamlines task management with a user-friendly interface. Quickly add, edit, and organize tasks to stay on top of your daily activities.
      </li>
      <li>
        Lockable Notes and Credentials:<br />
        Prioritize security with the ability to lock specific notes and credentials. Safeguard sensitive information with password protection, providing an extra layer of privacy.
      </li>
      <li>
        Customizable Categories:<br />
        Organize tasks and notes efficiently with customizable categories. Tailor the app to suit your unique workflow and preferences.
      </li>
    
      <li>
        Offline Functionality:<br />
        Enjoy uninterrupted productivity even without an internet connection. The app's offline functionality ensures you can manage your tasks and notes anytime, regardless of connectivity.
      </li>
    </ul>
    `,
  },
  "time-table-manager": {
    title: "Time Table Manager & Viewer",
    subtitle:
      "Browser extension that helps to seamlessly organize tasks, classes, and events for enhanced productivity and time management.",
    pageTitle: "Time Table Manager & Viewer | Chrome, Edge Extension",
    type: "Personal",
    date: "Aug 2020",
    link: {
      Chrome:
        "https://chromewebstore.google.com/detail/time-table-viewer-manager/eoebehpikcgoecnbenbeblobkjalhpan?pli=1",
      Edge: "https://microsoftedge.microsoft.com/addons/detail/time-table-viewer-manag/fhdjomjdpjcgdnpmefmiehijfcnacmop",
    },
    github: "#",

    expertise: "Browser Extension, HTML, CSS, JavaScript",
    platforms: "Chrome, Edge",
    deliverables: "UI Screens",
    heroImage: timeTableManager,

    overview:
      "Introducing the Time Table Manager Browser Extension, a dynamic scheduling tool designed to enhance productivity and organization. This innovative extension seamlessly integrates into users' web browsers, offering a streamlined solution for managing daily activities, assignments, and deadlines.",
    overviewImage: timeTableManagerOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Effortlessly Create Schedules:<br />
        Plan the day with ease by creating personalized schedules directly from the browser. The intuitive interface allows users to input classes, meetings, and tasks efficiently.
      </li>
      <li>
        Real-Time Updates:<br />
        Experience the convenience of real-time updates. Any modifications made to the schedule are instantly reflected, providing users with the latest information at a glance.
      </li>
      <li>
        Privacy and Security:<br />
        Personal schedules are kept in the browser's LocalStorage and are accessible only to the user.
      </li>
    </ul>
    `,
  },
  "zinnia-ngo": {
    title: "ZINNIA - The Voice Of Child",
    subtitle:
      "ZINNIA is a private, not-for-profit organization (NGO) that organizes various camps for child education and awareness.",
    pageTitle: "ZINNIA - The Voice Of Child",
    type: "Freelance",
    date: "Sept 2020",
    link: "https://zinnia.org.in/",
    github: "#",

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: zinia,

    overview:
      "ZINNIA is a private, not-for-profit organization (NGO) that organizes various camps for child education and awareness.",
    overviewImage: ziniaOverview,
    execution: `<b>Client description:</b>
    <br/>
    ZINNIA - The Voice Of Child is an organization initiated by a group of students to provide support to children facing physical, mental, or financial challenges. The organization aims to offer these children a platform to showcase their hidden talents, often overshadowed by the label of "disabled" imposed by our so-called "elite" society. Together, we can assist these children in discovering their rightful status and alter the world's perception of them.
    <br/><br/>
    While being busy can lead to wealth, true richness lies in feeling a deeper connection to others. Hence, we invite you to become a part of our cause. Join us in becoming the voice for these speechless children who represent the future of our country.`,
  },
  "ignite-educational-service": {
    title: "Ignite Educational Service",
    subtitle:
      "An Orissa (India) based coaching institute transforming education with experienced teachers and top-class facilities.",
    pageTitle: "Ignite Educational Service",
    type: "Freelance",
    date: "Mar 2020",
    link: "https://igniteeducationalservice.com",
    github: "#",

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: ignite,

    overview:
      "An Orissa (India) based coaching institute transforming education with experienced teachers and top-class facilities.",
    overviewImage: igniteOverview,
    execution: ``,
  },
  "avneet-kaur": {
    title: "Avneet Kaur",
    subtitle:
      "Avneet Kaur is an Indian television and film actress. The website highlighted her work, portfolio and awards.",
    pageTitle: "Avneet Kaur",
    type: "Freelance",
    date: "Feb 2019",
    link: { "avneetkaur.co.in (Decommissioned)": "#" },
    github: "#",

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: avneetKaur,

    overview:
      "Avneet Kaur is an Indian television and film actress. The website highlighted her work, portfolio and awards.",
    overviewImage: avneetKaurOverview,
    execution: ``,
  },
  "pinnacle-security-shield": {
    title: "Pinnacle Security Shield",
    subtitle:
      "An organization that provides physical Security, with VIP foreign delegations including film star and all industry.",
    pageTitle: "Pinnacle Security Shield",
    type: "Freelance",
    date: "April 2023",
    link: "https://www.pinnaclesecurityshield.com/",
    github: "#",

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: pinnacle,

    overview:
      "An organization that provides physical Security, with VIP foreign delegations including film star and all industry. Fire Fighting and cash Escort experienced Security personnel including house-keeping services to Govt./public/Private Enterprises.",
    overviewImage: pinnacleOverview,
    execution: `<b>Client description:</b>
    <br/>
    We provide specialized guarding services, based on our customer’s security requirements, we identify the needs and assess the risks involved. 
    <br/>
    The security deployment and delivery are customized and site and post instruction are tailor made to each customer need. Day to day situations is handled with utmost vigilance by our security personal. Our responsive cluster leader supervise and manage sites with use of app enabled tools.
    `,
  },
  "placement-assistance-chat-bot": {
    title: "Placement Assistance Chat Bot",
    subtitle:
      "Chat bot pre-trained to answer placement queries, can be configured for a different use case as well depending on the training data.",
    pageTitle: "Placement Assistance Chat Bot",
    type: "Personal",
    date: "Dec 2019",
    link: "https://placementbot.pythonanywhere.com/",
    github: "#",

    expertise: "Machine Learning, Flask, HTML, CSS, JavaScript, AJAX",
    platforms: "Web",
    deliverables: "UI Screens, Backend, ML Model",
    heroImage: placementBot,

    overview:
      "Chat bot pre-trained to answer placement queries, can be configured for a different use case as well depending on the training data.",
    overviewImage: placementBotOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Tailored Guidance:<br />
        The Placement Assistance Chatbot offers personalized advice to job seekers based on their skills, experience, and career aspirations. It tailors recommendations to individual profiles, ensuring relevant and targeted assistance.
      </li>
      <li>
        Interactive Q&A:<br />
        Engage in interactive Q&A sessions with the chatbot to clarify doubts, seek advice, and receive instant responses to your career-related queries.
      </li>
      <li>
        User-Friendly Interface:<br />
        The user-friendly design ensures a seamless experience, making the Placement Assistance Chatbot accessible to users of all levels of technical proficiency.
      </li>
    </ul>
    `,
  },
  "hospital-management-system": {
    title: "Hospital Management System",
    subtitle:
      "A fully fledged web app with capabilities like tracking in/ out patients, generating bills, and tracking staff attendance.",
    pageTitle: "Hospital Management System",
    type: "Freelance",
    date: "Jan 2021",
    link: "NA, Private",
    github: "#",

    expertise: "Express JS, MySQL, JavaScript, AJAX, HTML, CSS",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: hospitalManagement,

    overview:
      "A fully fledged web app with capabilities like tracking in/ out patients, generating bills, and tracking staff attendance. This system aims to streamline and enhance the overall efficiency of hospital operations.",
    overviewImage: hospitalManagementOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Patient Information Management:<br />
        The Hospital Management System efficiently manages patient records, including personal details, medical history, and treatment plans. This feature ensures a centralized and organized repository of patient information.
      </li>
      <li>
        Billing and Invoicing:<br />
        Simplify financial transactions with a robust billing and invoicing module. The system automates billing processes, reducing errors and providing accurate financial records for both patients and the hospital.
      </li>
      <li>
        Doctor and Staff Management:<br />
        Effectively manage doctor and staff schedules, assignments, and responsibilities. This feature ensures efficient resource allocation and enhances collaboration among healthcare professionals.
      </li>
      <li>
        Prescription Management:<br />
        Enhance prescription accuracy and accessibility with a dedicated prescription management system. Doctors can easily create and share digital prescriptions, improving communication and reducing errors.
      </li>
      <li>
        Reporting and Analytics:<br />
        Gain valuable insights into hospital operations with comprehensive reporting and analytics tools. Track key performance indicators, identify trends, and make data-driven decisions to optimize efficiency.
      </li>
    </ul>
    `,
  },
  "school-management-system": {
    title: "School Management System",
    subtitle:
      "The web application enables schools to manage admission, fee collection, daily student attendance, generating results, etc.",
    pageTitle: "School Management System",
    type: "Freelance",
    date: "Feb 2020",
    link: "Retired",
    github: "#",

    expertise: "Express JS, MySQL, JavaScript, AJAX, HTML, CSS",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: schoolManagement,

    overview:
      "The web application enables schools to manage admission, fee collection, daily student attendance, generating results, etc. This comprehensive system is tailored to meet the diverse needs of schools, offering a centralized platform to streamline operations and elevate overall efficiency.",
    overviewImage: schoolManagementOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Student Information Management:<br />
        The School Management System efficiently manages student records, including personal details, academic history, attendance, and extracurricular activities. This centralized repository ensures accurate and accessible information.
      </li>
      <li>
        Teacher and Staff Management:<br />
        Effectively organize teacher and staff information, including schedules, roles, and responsibilities. The system optimizes resource allocation and facilitates collaboration among educators.
      </li>
      <li>
        Attendance Tracking:<br />
        Streamline attendance tracking with a user-friendly interface. Teachers can easily record and monitor student attendance, promoting transparency and accountability.
      </li>
    
      <li>
        Gradebook and Academic Records:<br />
        Simplify grading processes with a dedicated gradebook module. The system provides a secure platform for teachers to manage grades, assessments, and academic progress.
      </li>
    
      <li>
        Fee and Financial Management:<br />
        Streamline financial transactions with a robust fee management module. The system automates fee collection, generates invoices, and maintains accurate financial records.
      </li>
    </ul>
    `,
  },

  // Decominissioned sites starts

  "sharda-kendra": {
    title: "Sharda Kendra",
    subtitle:
      "The web application was developed for an organization providing government services like, Adhar update, ITR Filing, etc.",
    pageTitle: "Sharda Kendra",
    type: "Freelance",
    date: "Aug 2021",
    link: { "shardakendra.com (Decommissioned)": "#" },
    github: "#",

    expertise: "PHP, MySQL, JavaScript, AJAX, HTML, CSS",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: shardaKendra,

    overview:
      "The web application was developed for an organization providing government services like, Adhar update, ITR Filing, Caste Certificate, Date of Birth Certificate, Residence Certificates, etc.",
    overviewImage: shardaKendraOverview,
    execution: ``,
  },
  "strength-bricks": {
    title: "Strength Bricks",
    subtitle:
      "Website for brick company providing and exporting various types of construction materials, based in Jharkhand, India.",
    pageTitle: "Strength Bricks",
    type: "Freelance",
    date: "Jul 2019",
    link: { "strengthbricks.com (Decommissioned)": "#" },
    github: "#",

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: strengthBricks,

    overview:
      "Website for brick company providing and exporting various types of construction materials like usual Mud bricks, CLC bricks, etc., based in Jharkhand, India.",
    overviewImage: strengthBricksOverview,
    execution: ``,
  },
  "motion-plus": {
    title: "Motion Plus",
    subtitle:
      "A Ranchi (India) based coaching institute transforming education with experienced teachers and top-class facilities.",
    pageTitle: "Motion Plus",
    type: "Freelance",
    date: "Feb 2019",
    link: "Decommissioned",
    github: { "motionplus.in (Decommissioned)": "#" },

    expertise: "HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: motionPlus,

    overview:
      "A Ranchi (India) based coaching institute transforming education with experienced teachers and top-class facilities.",
    overviewImage: motionPlusOverview,
    execution: ``,
  },
  "tedx-lpu": {
    title: "TedX LPU",
    subtitle:
      "Designed and developed for TedX Chapter LPU, for scheduling, organizing and managing events.",
    pageTitle: "TedX LPU",
    type: "Freelance",
    date: "Feb 2021",
    link: { "tedxlpu.in (Decommissioned)": "#" },
    github: "#",

    expertise: "React, Redux, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: tedxLpu,

    overview:
      "Designed and developed for TedX Chapter LPU, for scheduling, organizing and managing events.",
    overviewImage: tedxLpuOverview,
    execution: ``,
  },
  "quiz-typescript": {
    title: "Quiz - TypeScript",
    subtitle:
      "A TypeScript based quiz application for taking user responses and displaying result in an intutive and graphical fashion.",
    pageTitle: "Quiz - TypeScript",
    type: "Freelance",
    date: "Apr 2021",
    link: "https://pratikktiwari.github.io/Quiz-app-typescript/",
    github: "#",

    expertise: "TypeScript, HTML, CSS",
    platforms: "Web",
    deliverables: "UI Screens, Logic",
    heroImage: typescriptQuiz,

    overview:
      "Quiz - A TypeScript based quiz application for taking user responses and displaying result in an intutive and graphical fashion.",
    overviewImage: typescriptQuiz,
    execution: ``,
  },
  "recipe-search": {
    title: "Recipe Search",
    subtitle:
      "Designed to inspire creativity by offering a user-friendly platform to discover and explore a diverse range of delightful recipes.",
    pageTitle: "Recipe Search",
    type: "Personal",
    date: "Aug 2019",
    link: "Decommissioned",
    github: "#",

    expertise: "PHP, HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens",
    heroImage: recipeSearch,

    overview:
      "Designed to inspire creativity by offering a user-friendly platform to discover and explore a diverse range of delightful recipes. Results are displayed using Recipe Puppy APIs.",
    overviewImage: recipeSearchOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Vast Recipe Database:<br />
        The Recipe Search Web App boasts an extensive database, offering a diverse array of recipes to suit every palate and culinary preference.
      </li>
      <li>
        User-Friendly Interface:<br />
        With a focus on user experience, the app features a clean and intuitive interface, ensuring easy navigation for users of all culinary skill levels.
      </li>
      <li>
        Responsive Design:<br />
        Whether accessed from a computer, tablet, or mobile device, the Recipe Search Web App features a responsive design, ensuring a seamless experience across various platforms.
      </li>
    </ul>
    `,
  },

  // Decominissioned sites ends

  "certificate-generator": {
    title: "Certificate Generator",
    subtitle:
      "Creating certificates in bulk is a tedious task if done manually for each certificate. The web app solves the issue in an intuitive way.",
    pageTitle: "Certificate Generator",
    type: "Personal",
    date: "May 2019",
    link: "Discontinued",
    github: "#",

    expertise: "PHP, MySQL, HTML, CSS, JavaScript",
    platforms: "Web",
    deliverables: "UI Screens, Backend",
    heroImage: certGenerator,

    overview:
      "Creating certificates in bulk is a tedious task if done manually for each certificate. The web app solves the issue in an intuitive way. This innovative tool is specifically designed to streamline and simplify the process of creating certificates in large quantities, making it an invaluable asset for schools and colleges.",
    overviewImage: certGeneratorOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Effortless Bulk Certificate Creation:<br />
        The Bulk Certificate Generator is a time-saving solution that enables schools and colleges to effortlessly generate certificates in large quantities, eliminating the need for manual and time-consuming processes.
      </li>
      <li>
        Customizable Templates:<br />
        Tailor certificates to specific requirements with customizable templates. The project ensures flexibility in design, allowing for the incorporation of school logos, unique colors, and other personalized elements.
      </li>
      <li>
        Bulk Data Import:<br />
        Streamline data entry with the capability to import bulk data. This feature is especially useful for institutions with a large number of recipients, enabling quick and accurate certificate generation.
      </li>
    
      <li>
        Print-Ready Output:<br />
        Generate print-ready certificates, minimizing additional steps in the printing process. The project delivers certificates in formats suitable for immediate use, maintaining a professional and polished appearance.
      </li>
    </ul>
    `,
  },
  "car-price-predictor": {
    title: "Car Price Predictor",
    subtitle:
      "Tkinter based desktop application pre-trained with dataset to predict car prices based on various parameters.",
    pageTitle: "Car Price Predictor",
    type: "Personal",
    date: "Jan 2021",
    link: "NA",
    github: "#",

    expertise: "Tkinter, Machine Learning, Python",
    platforms: "Windows, Desktop",
    deliverables: "UI Screens, ML Model",
    heroImage: carPrice,

    overview: `Tkinter based desktop application pre-trained with dataset to predict car prices based on various parameters.
      <br/>This predictive tool is designed to empower potential car buyers with valuable insights into anticipated prices, aiding them in making informed purchasing decisions.`,
    overviewImage: carPriceOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Machine Learning Algorithms:<br />
        The Car Price Predictor leverages machine learning algorithms to analyze historical car data, considering factors such as model, year, mileage, and additional features to predict accurate price estimates.
      </li>
      <li>
        User-Friendly Interface:<br />
        Featuring an intuitive and user-friendly interface, this project ensures that users, regardless of their technical expertise, can easily input relevant information and receive reliable price predictions for a wide range of car models.
      </li>
      <li>
        Data-Driven Insights:<br />
        Offering data-driven insights, the Car Price Predictor provides users with a comprehensive understanding of the various factors influencing car prices. This empowers them to make well-informed decisions based on real-time market trends.
      </li>
    </ul>`,
  },
  "student-result-predictor": {
    title: "Student Result Predictor",
    subtitle:
      "Tkinter based desktop application to predict the performance on students based on their interests, previous records, etc.",
    pageTitle: "Student Result Predictor",
    type: "Personal",
    date: "Feb 2021",
    link: "NA",
    github: "#",

    expertise: "Tkinter, Machine Learning, Python",
    platforms: "Windows, Desktop",
    deliverables: "UI Screens, ML Model",
    heroImage: studentLearner,

    overview: `Tkinter based desktop application to predict the performance on students based on their interests, previous records, etc.
      <br/>It  empowers students and educators by providing insightful predictions of academic outcomes based on various performance metrics.`,
    overviewImage: studentLearnerOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Predictive Analytics:<br />
        The Student Result Predictor employs advanced predictive analytics, utilizing historical academic data to forecast potential future results. It analyzes factors such as past grades, study habits, and attendance to generate accurate predictions.
      </li>
      <li>
        User-Friendly Interface:<br />
        With a user-centric design, the project ensures accessibility for users of all technical backgrounds. The intuitive interface allows students and educators to input relevant information easily, facilitating a seamless experience.
      </li>
      <li>
        Data-Driven Insights:<br />
        Offering data-driven insights, the Result Predictor provides users with a comprehensive understanding of the various factors influencing grades.
      </li>
    </ul>`,
  },
  "news-sentiment-analysis": {
    title: "News Sentiment Analysis",
    subtitle:
      "Classifies a news into negative/ postive/ neutral based on pre-trained news data set, built with Python Tkinter.",
    pageTitle: "News Sentiment Analysis",
    type: "Personal",
    date: "Feb 2021",
    link: "NA",
    github: "#",

    expertise: "Tkinter, Machine Learning, Python",
    platforms: "Windows, Desktop",
    deliverables: "UI Screens, ML Model",
    heroImage: newsSentiment,

    overview:
      "Classifies a news into negative/ postive/ neutral based on pre-trained news data set, built with Python Tkinter.",
    overviewImage: newsSentimentOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        Sentiment Analysis:<br />
        The News Sentiment Analysis tool evaluates the sentiment expressed in news articles, categorizing them as positive, negative, or neutral. This enables users to gauge the overall emotional tone of the news content.
      </li>
      <li>
        User-Friendly Interface:<br />
        With a user-centric design,the intuitive interface allows for easy navigation and input, making sentiment analysis accessible to a broad audience.
      </li>
    </ul>`,
  },
  "courier-tracker": {
    title: "Courier Manager",
    subtitle:
      "Tkinter based desktop application that courier owners/ users manage and track orders in a streamlined way.",
    pageTitle: "Courier Manager",
    type: "Personal",
    date: "Nov 2020",
    link: "NA",
    github: "#",

    expertise: "Tkinter, Python, SQLite",
    platforms: "Windows, Desktop",
    deliverables: "UI Screens, Backend",
    heroImage: courierManager,

    overview:
      "Tkinter based desktop application that courier owners/ users manage and track orders in a streamlined way.",
    overviewImage: courierManager,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        User-Friendly Interface with Tkinter:<br />
        The Courier Manager boasts a user-friendly interface built with the Tkinter framework, ensuring a seamless and intuitive experience for users. This design choice makes it accessible to individuals with varying levels of technical expertise.
      </li>
      <li>
        Efficient Inventory Tracking:<br />
        This project facilitates efficient tracking of Courier items, enabling businesses to monitor stock levels, product details, and restocking requirements in real-time. Users can easily add, edit, or remove items from the inventory database.
      </li>
      <li>
        Customizable Categories and Labels:<br />
        Tailor the organization of Courier items with customizable categories and labels. The Courier Manager allows users to categorize products based on specific criteria, facilitating easy navigation and retrieval of information.
      </li>
    </ul>
    `,
  },
  "inventory-manager": {
    title: "Inventory Manager",
    subtitle:
      "Tkinter based desktop application that shop owners manage their inventories in a streamlined way.",
    pageTitle: "Inventory Manager",
    type: "Personal",
    date: "Dec 2020",
    link: "NA",
    github: "#",

    expertise: "Tkinter, Python, SQLite",
    platforms: "Windows, Desktop",
    deliverables: "UI Screens, Backend",
    heroImage: inventoryManager,

    overview:
      "Tkinter based desktop application that shop owners manage their inventories in a streamlined way.",
    overviewImage: inventoryManagerOverview,
    execution: `<strong>Key features:</strong>
    <ul>
      <li>
        User-Friendly Interface with Tkinter:<br />
        The Inventory Manager boasts a user-friendly interface built with the Tkinter framework, ensuring a seamless and intuitive experience for users. This design choice makes it accessible to individuals with varying levels of technical expertise.
      </li>
      <li>
        Efficient Inventory Tracking:<br />
        This project facilitates efficient tracking of inventory items, enabling businesses to monitor stock levels, product details, and restocking requirements in real-time. Users can easily add, edit, or remove items from the inventory database.
      </li>
      <li>
        Customizable Categories and Labels:<br />
        Tailor the organization of inventory items with customizable categories and labels. The Inventory Manager allows users to categorize products based on specific criteria, facilitating easy navigation and retrieval of information.
      </li>
    </ul>
    `,
  },
};
