import React from "react";
import Card from "../Card/Card";
import "./ResumeSection.css";
import VMwareImage from "../assets/images/vmware.png";
import VMwareBroadcomImage from "../assets/images/vmware-by-broadcom.svg";
import MAQSoftware from "../assets/images/maq-software.png";
import MicrosoftImage from "../assets/images/microsoft.png";
import Freelancer from "../assets/images/freelance.webp";
import underlineResumeImage from "../assets/images/underline-resume.svg";
import { Link } from "react-router-dom";

const ResumeSection = () => {
  return (
    <section className="section">
      <div className="container-default">
        <div className="top-content-resume" data-aos="fade">
          <h2 className="title resume-section">My work experiences</h2>
          <img
            className="image resume-underline"
            src={underlineResumeImage}
            loading="lazy"
            alt="na"
          />
          <Link className="btn btn-primary" to="/portfolio" data-aos="fade-up">
            See full portfolio
          </Link>
        </div>
        <div className="services-main-wrapper">
          <div className="grid-container">
            <Card
              title="Software Engineer"
              subtitle="Working as a part of Core User Experience Team, on Microsoft Copilot and Bing."
              date="May 2024 - Present"
              companyName="Microsoft"
              link="https://microsoft.com/"
              icon={MicrosoftImage}
              aos="fade"
            />
            <Card
              title="MTS 2"
              subtitle="Received <i><strong>At Our Best</strong></i> award for significant contributions. Mastered multiple tech stacks to automate processes, significantly reducing down time for certain tasks."
              date="Jan 2022 - April 2024"
              companyName="VMware"
              link="https://vmware.com/"
              icon={VMwareBroadcomImage}
              aos="fade"
            />
            {/* <Card
              title="MTS 2"
              subtitle="Working on a web application designed to seamlessly manage failover and failback processes for VMware SaaS services across different Data Centers."
              date="Aug 2023 - April 2024"
              companyName="VMware"
              link="https://vmware.com/"
              icon={VMwareBroadcomImage}
              aos="fade"
            />
            <Card
              title="MTS 1"
              subtitle="Received <i><strong>At Our Best</strong></i> award for significant contributions. Mastered multiple tech stacks to automate processes, significantly reducing down time for certain tasks."
              date="Jan 2022 - July 2023"
              companyName="VMware"
              link="https://vmware.com/"
              icon={VMwareImage}
              aos="fade"
            /> */}
            <Card
              title="Associate SDE"
              subtitle="Worked on a web app (client - Microsoft) that enables users to embed Power BI reports. Worked on complex features like enabling comments for individual reports, managing report exports, etc."
              date="March 2021 - Dec 2021"
              companyName="VMware"
              link="https://maqsoftware.com/"
              icon={MAQSoftware}
              aos="fade"
            />
            <Card
              title="Web Developer"
              subtitle="Developed 14 websites as a freelancer. Interacted with clients to understand their requirements and delivered tailored solutions that elevated digital experiences and drove success."
              date="Jan 2020 - Dec 2020"
              companyName="VMware"
              link="https://pratiktiwari.com/"
              icon={Freelancer}
              aos="fade"
            />
          </div>
          <div className="bg card-square"></div>
        </div>
      </div>
    </section>
  );
};

export default ResumeSection;
