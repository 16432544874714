import React from "react";

const ProjectOverview = (props) => {
  const { overview, overviewImage, execution } = props;
  return (
    <>
      <section className="section pd-top-bottom-150px" data-aos="fade">
        <div className="portfolio-project-container">
          <div className="card portfolio-project-title">
            <h2 className="title portfolio">Project Overview</h2>
          </div>
          <div className="split-content portfolio-project-content reduced-margin">
            <div className="rich-text w-richtext">
              <p dangerouslySetInnerHTML={{ __html: overview }}></p>
              <figure
                style={{ maxWidth: "1320px" }}
                className="w-richtext-align-fullwidth"
              >
                <div>
                  <img alt="na" src={overviewImage} />
                </div>
              </figure>
            </div>
          </div>
        </div>
      </section>
      {execution && (
        <>
          <div className="divider"></div>
          <section className="section pd-top-bottom-150px" data-aos="fade">
            <div className="portfolio-project-container">
              <div className="card portfolio-project-title">
                <h2 className="title portfolio">Execution</h2>
              </div>
              <div className="split-content portfolio-project-content reduced-margin">
                <div className="rich-text w-richtext">
                  <p dangerouslySetInnerHTML={{ __html: execution }}></p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ProjectOverview;
