import React from "react";
import "./Slider.css";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

const Slider = (props) => {
  const handleScroll = (scrollLeft = true) => {
    const viewElement = document.querySelector(".hidden-scroll-child");
    const portfolioSectionHome = document.querySelector(
      ".portfolio-section-home"
    );
    portfolioSectionHome.style.maxWidth = "100vw";
    // portfolioSectionHome.style.paddingLeft = "20px";
    // portfolioSectionHome.style.paddingRight = "20px";
    if (scrollLeft) {
      viewElement.scrollBy(-600, 0);
    } else {
      viewElement.scrollBy(600, 0);
    }
  };
  return (
    <div className="slider-main-wrapper">
      <div className="scroll-buttons">
        <div onClick={() => handleScroll(true)}>
          <IoChevronBackOutline className="chevron-slider-icon" />
        </div>
        <div onClick={() => handleScroll(false)}>
          <IoChevronForwardOutline className="chevron-slider-icon" />
        </div>
      </div>
      <div className="hidden-scroll-wrapper">
        <div className="hidden-scroll-child">
          <div className="slider-nav-from-top">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
