import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./About.css";
import PratikTiwariImage from "../assets/images/pratik-k-tiwari.jpg";
import JiwanAthvaPrem from "../assets/images/jeewan-athva-prem.jpg";
import PratikTiwariImage1 from "../assets/images/pratik-tiwari-1.jpg";
import PratikTiwariImage3 from "../assets/images/pratik-banshidhar.jpg";
import PratikTiwariImage4 from "../assets/images/pratik-radhe-1.jpg";

const LINKS = {
  AMAZON:
    "https://www.amazon.in/%E0%A4%9C%E0%A5%80%E0%A4%B5%E0%A4%A8-%E0%A4%85%E0%A4%A5%E0%A4%B5%E0%A4%BE-%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A5%87%E0%A4%AE-Jiwan-Athva/dp/9360941727",
  FLIPKART: "https://www.flipkart.com/jiwan-athva-prem/p/itmb18ea2efe3f9f",
};

const AboutSection = (props) => {
  const { page, title, subtitle } = props;
  const handleLinkClick = () => {
    window.open(LINKS.AMAZON, "_blank");
    setTimeout(() => window.open(LINKS.FLIPKART, "_blank"), 500);
  };
  return (
    <div className="section container-default" id="about-section">
      <div className="about-wrapper">
        <div className="image-wrapper" data-aos="fade-right">
          {/* <img alt="na" src="https://assets-global.website-files.com/61129bb2ecedf803564c68ec/6112b5031b9bac7e65bfa8d1_home-about-personal-x-template.jpg" /> */}
          {page ? <img alt="na" src={JiwanAthvaPrem} /> : <CarouselAbout />}
        </div>
        <div className="about-details" data-aos="fade-left">
          <h2 className="title home-about">{title ? title : "About me"}</h2>

          <p className="paragraph about-paragraph">
            {subtitle
              ? subtitle
              : `I am a full stack developer. With a
            passion for creating seamless digital experiences, I bring expertise
            in diverse technologies to transform ideas into working
            solutions that drive progress and growth.`}
          </p>
          {page ? (
            <div
              className="btn btn-primary"
              data-aos="fade-up"
              onClick={handleLinkClick}
            >
              जीवन अथवा प्रेम
            </div>
          ) : (
            <Link className="btn btn-primary" to="/about" data-aos="fade-up">
              More about me
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const CarouselAbout = () => {
  return (
    <Carousel>
      <div className="carousel-about-div">
        <img alt="na" src={PratikTiwariImage} />
        {/* <p className="legend">Legend 1</p> */}
      </div>
      <div className="carousel-about-div">
        <img alt="na" src={PratikTiwariImage1} />
      </div>
      <div className="carousel-about-div">
        <img alt="na" src={PratikTiwariImage3} />
      </div>
      <div className="carousel-about-div">
        <img alt="na" src={PratikTiwariImage4} />
      </div>
    </Carousel>
  );
};

export default AboutSection;
