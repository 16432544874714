import React from "react";
import "./FooterSection.css";
import {
  PiYoutubeLogoLight,
  PiGithubLogo,
  PiStackOverflowLogoLight,
  PiLinkedinLogo,
  PiTwitterLogoLight,
  PiInstagramLogoLight,
} from "react-icons/pi";
import { CiMail } from "react-icons/ci";
import { SocialLinks } from "../utils/utils";


const FooterSection = () => {
  return (
    <section className="section footer container-default" id="footer">
      <div className="text-center">
        {/* animate__animated animate__pulse */}
        <p className="">
          Made with lots of Love &nbsp;
          <span className="animate__animated animate__heartBeat hvr-pulse-grow">
            ❤️
          </span>
        </p>
        <div className="links">
          <a
            href={SocialLinks.LINKEDIN}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiLinkedinLogo className="social-icons" />
          </a>
          <a
            href={SocialLinks.GITHUB}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiGithubLogo className="social-icons" />
          </a>
          <a
            href={SocialLinks.STACK_OVERFLOW}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiStackOverflowLogoLight className="social-icons" />
          </a>
          <a
            href={SocialLinks.TWITTER}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiTwitterLogoLight className="social-icons" />
          </a>
          <a
            href={SocialLinks.INSTAGRAM}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiInstagramLogoLight className="social-icons" />
          </a>
          <a
            href={SocialLinks.YOUTUBE}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <PiYoutubeLogoLight className="social-icons" />
          </a>
          <a
            href={SocialLinks.EMAIL}
            target="_blank"
            className="animate__animated animate__tada hvr-pulse-grow"
          >
            <CiMail className="social-icons" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default FooterSection;
