import AboutSection from "../AboutSection/AboutSection";
import FooterSection from "../FooterSection/FooterSection";
import Header from "../Header/Header";
import Hero from "../Hero/Hero";
import PortfolioSection from "../PortfolioSection/PortfolioSection";
import ResumeSection from "../ResumeSection/ResumeSection";
import ServicesSection from "../ServicesSection/Services";
import SkillsSection from "../SkillsSection/SkillsSection";

function Home() {
  return (
    <>
      <Header />
      <Hero />
      <ServicesSection />
      <AboutSection />
      <ResumeSection />
      <SkillsSection />
      <PortfolioSection />
      <FooterSection />
    </>
  );
}

export default Home;
