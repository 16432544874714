import React from "react";

import "./Services.css";
import Card from "../Card/Card";
import CodeIcon from "../assets/images/code-icon.svg";
import PenIcon from "../assets/images/pen-icon.svg";
import HandIcon from "../assets/images/hand-icon.svg";
import BulbIcon from "../assets/images/bulb-icon.svg";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  return (
    <section className="section services-section container-default">
      <div className="container-small text-center" data-aos="zoom-in">
        <h2>My Tech Stacks</h2>
        <p>
          I mostly work with the listed technologies, but picking up a new
          framework or language isn't a problem.
        </p>
      </div>
      <div className="services-main-wrapper">
        <div className="grid-container">
          <Card
            title="Full Stack "
            subtitle="Versatile full-stack developer with expertise in front-end and back-end technologies, crafting seamless, dynamic web solutions with precision and efficiency."
            icon={CodeIcon}
            aos="zoom-in-right"
          />
          <Card
            title="Mobile App "
            subtitle="Proficient React Native developer adept at crafting powerful, cross-platform hybrid apps, delivering seamless user experiences and processing."
            icon={PenIcon}
            aos="zoom-in-left"
          />
          <Card
            title="Dev Ops"
            subtitle="Orchestrating seamless workflows, automating deployments, and optimizing infrastructure for efficient, resilient, and scalable software delivery."
            icon={HandIcon}
            aos="zoom-in-right"
          />
          <Card
            title="Microservices"
            subtitle="Experienced in microservices architecture, proficient in Redis for caching, and skilled in RabbitMQ for robust message queuing in distributed systems."
            icon={BulbIcon}
            aos="zoom-in-left"
          />
        </div>
        <div className="bg card-square"></div>
      </div>
      <div className="btn-container">
        <Link className="btn btn-primary" data-aos="fade-up" to="/contact">
          Get in touch
        </Link>
      </div>
    </section>
  );
};

export default ServicesSection;
