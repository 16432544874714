import React from "react";
import "./SkillsSection.css";
import underlineImage from "../assets/images/underline-skills.svg";
import dividerImage from "../assets/images/icon-divider.svg";
import pcImage from "../assets/images/icon-pc.svg";
import toolsImage from "../assets/images/icon-tools.svg";

const SkillsCard = (props) => {
  const { title, image, skills, aos } = props;
  return (
    <div className="card skill-card" data-aos={aos}>
      <img alt="na" className="image skill-icon" src={image} loading="eager" />
      <h3 className="title skill-name">{title}</h3>
      {skills.map((item, index) => (
        <div key={index} className="title skill">
          {item}
        </div>
      ))}
    </div>
  );
};

const FrontendSkills = [
  "React, Redux",
  "Angular",
  "React Native",
  "Jest - Unit Testing",
  "TypeScript",
  "JavaScript",
  "HTML",
  "CSS, SCSS",
];
const BackendSkills = [
  "Django",
  "Express JS",
  "PHP",
  "MySQL",
  "Redis",
  "Rabbit MQ",
  "Java, Python, C++",
  "-",
];
const ToolsSkills = [
  "Docker",
  "Kubernetes",
  "GitLab CI/ CD",
  "REST APIs",
  "OAuth 2.0",
  "Swagger",
  "Helm",
  "Linux, Git",
];

const SkillsSection = () => {
  return (
    <section className="section container-default skills-section">
      <div className="text-center container-small my-skills" data-aos="fade">
        <h2 className="title my-skills">My skills & tools</h2>
        <img
          className="image skills-underline"
          src={underlineImage}
          loading="lazy"
        />
        <p>
          Versatile full-stack developer adept at crafting responsive
          interfaces, and implementing efficient solutions.
        </p>
      </div>
      <div className="_3-column-grid skills-grid">
        <SkillsCard
          title="Front End"
          image={dividerImage}
          skills={FrontendSkills}
          aos="fade-right"
        />
        <SkillsCard
          title="Back End"
          image={pcImage}
          skills={BackendSkills}
          aos="fade"
        />
        <SkillsCard
          title="Tools"
          image={toolsImage}
          skills={ToolsSkills}
          aos="fade-left"
        />
      </div>
    </section>
  );
};
export default SkillsSection;
