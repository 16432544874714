import React, { useLayoutEffect } from "react";

import {
  PiYoutubeLogoLight,
  PiGithubLogo,
  PiStackOverflowLogoLight,
  PiLinkedinLogo,
  PiTwitterLogoLight,
  PiInstagramLogoLight,
} from "react-icons/pi";
import { CiMail } from "react-icons/ci";
import { TbBrandBlogger } from "react-icons/tb";

import { IoMailOutline } from "react-icons/io5";
import { IoIosMenu } from "react-icons/io";

import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import logoImage from "../assets/images/logo.png";
import { SocialLinks } from "../utils/utils";

const Header = () => {
  const handleNavExpand = () => {
    const navHeader = document.querySelector(".nav-header");
    if (!navHeader) return;
    if (navHeader.clientHeight === 90) {
      navHeader.style.height = "auto";
    } else {
      navHeader.style.height = "90px";
    }
  };

  const location = useLocation();
  useLayoutEffect(() => {
    console.log("location", location.pathname);
  }, [location]);

  const getNavClassName = (path) => {
    if (location.pathname.indexOf(path.toLowerCase()) !== -1) {
      return "t-hover-fade nav-active-item";
    }
    return "t-hover-fade";
  };

  return (
    <header
      className="nav-header container-default animateAnimated animate__fadeInDown"
      data-aos="fade-down"
    >
      <Link to="/">
        <img alt="Logo" src={logoImage} className="header-logo-main" />
      </Link>

      <ul>
        <li>
          <Link className={getNavClassName("Home")} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link className={getNavClassName("About")} to="/about">
            About
          </Link>
        </li>
        <li>
          <Link className={getNavClassName("Contact")} to="/contact">
            Contact
          </Link>
        </li>
        <li>
          <Link className={getNavClassName("Portfolio")} to="/portfolio">
            Portfolio
          </Link>
        </li>
      </ul>

      <div className="header-contact-email">
        {/* <a
          href="mailto:ipratiktiwari@gmail.com"
          className="flex v-center t-hover-fade"
        >
          <IoMailOutline className="mail-icon" />
          ipratiktiwari@gmail.com
        </a> */}
        <div className="links">
          <a
            href={SocialLinks.BLOG}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <TbBrandBlogger className="social-icons" />
          </a>
          <a
            href={SocialLinks.LINKEDIN}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <PiLinkedinLogo className="social-icons" />
          </a>
          <a
            href={SocialLinks.GITHUB}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <PiGithubLogo className="social-icons" />
          </a>
          <a
            href={SocialLinks.STACK_OVERFLOW}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <PiStackOverflowLogoLight className="social-icons" />
          </a>
          <a
            href={SocialLinks.EMAIL}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <CiMail className="social-icons" />
          </a>
          <a
            href={SocialLinks.INSTAGRAM}
            target="_blank"
            className="hvr-pulse-grow"
          >
            <PiInstagramLogoLight className="social-icons" />
          </a>
        </div>
      </div>
      <div className="header-expand-button">
        <IoIosMenu className="menu-icon-header" onClick={handleNavExpand} />
      </div>
    </header>
  );
};

export default Header;
