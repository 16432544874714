import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import FooterSection from "../FooterSection/FooterSection";
import Header from "../Header/Header";
import { PortfolioList } from "./PortfolioList";
import PortfolioHero from "./PortfolioHero";
import "./Portfolio.css";
import ProjectOverview from "./ProjectOverview";

function Portfolio() {
  const { name } = useParams();

  const portflio = PortfolioList[name];

  useEffect(() => {
    if (portflio) {
      document.title = portflio.pageTitle;
    } else {
      document.title = "Portfolio not found!";
    }
  }, []);

  if (!portflio) {
    return (
      <>
        <Header />
        <h2 className="text-center container-small pd-top-bottom-200px">
          The portfolio you've requested doesn't exist.
        </h2>
        ;
        <FooterSection />
      </>
    );
  }

  return (
    <>
      <Header />
      <PortfolioHero {...portflio} />
      <ProjectOverview {...portflio} />
      <FooterSection />
    </>
  );
}

export default Portfolio;
