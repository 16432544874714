import React from "react";
import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import { PortfolioList as PortList } from "../Portfolio/PortfolioList";
import "./PortfolioList.css";

const PortfolioList = () => {
  return (
    <>
      <Header />
      <section className="section container-default portfolio-hero">
        <div data-aos="fade">
          <h1 className="title portfolio-title text-center">Portfolio</h1>
        </div>
        <div
          className="grid-container portfolio-grid w-dyn-items"
          data-aos="fade"
        >
          {Object.keys(PortList).map((item, index) => (
            <div className="portfolio-page-card-wrapper" key={index}>
              <PortfolioCard
                title={PortList[item].title}
                subtitle={PortList[item].subtitle}
                link={item}
                status={PortList[item].link}
                image={PortList[item].heroImage}
              />
            </div>
          ))}
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default PortfolioList;
