export const Constants = {
  EMAIL: "ipratiktiwari@gmail.com",
  FIRST_NAME: "Pratik",
  LAST_NAME: "Tiwari",
  FULL_NAME: "Pratik Tiwari",
};

export const scrollIntoView = (selector) => {
  const element = document.querySelector(selector);
  if (element) {
    element.scrollIntoView();
  }
};

export const SocialLinks = {
  LINKEDIN: "https://linkedin.com/in/pratikktiwari/",
  GITHUB: "https://github.com/pratikktiwari/",
  STACK_OVERFLOW: "https://stackoverflow.com/users/10260337/pratik-k-tiwari",
  TWITTER: "https://twitter.com/pratikktiwari/",
  INSTAGRAM: "https://instagram.com/DevTonics",
  YOUTUBE: "https://youtube.com/@DevTonics",
  BLOG: "https://devtonics.in/",
  EMAIL: "mailto:ipratiktiwari@gmail.com",
};