import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";
import PratikTiwariHeroImage from "../assets/images/pratik-radhe.png";
import underlineImage1 from "../assets/images/underline-1.svg";
import { Constants } from "../utils/utils";

const Hero = () => {
  return (
    <section className="hero-section container-default">
      <div className="left" data-aos="fade-right">
        <h3 className="subtitle">Hey, I'm {Constants.FULL_NAME}</h3>
        <h1 className="title">
          {/* I enjoy designing tech websites and digital products */}
          {"I enjoy developing innovative tech solutions."}
        </h1>
        <img
          alt="na"
          className="hero-underline"
          src={underlineImage1}
          loading="eager"
        />
        <div>
          <Link className="btn btn-primary" to="/contact">
            Get in touch
          </Link>
        </div>
      </div>
      <div className="right" data-aos="fade-left">
        <img alt="na" src={PratikTiwariHeroImage} className="hero-image-home" />
        {/* <img alt="na" src="https://assets-global.website-files.com/61129bb2ecedf803564c68ec/6112af58d6338c4181aaed8c_hero-image-personal-x-template.png" /> */}
      </div>
    </section>
  );
};

export default Hero;
