import React from "react";
import "./PortfolioCard.css";
import { GoArrowRight } from "react-icons/go";
import { Link } from "react-router-dom";

const PortfolioCard = (props) => {
  const { title, subtitle, link, image } = props;
  return (
    <div className="portfolio-card-container">
      <div className="image-wrapper portfolio-card">
        <img
          src={image}
          alt={title}
          // sizes="(max-width: 479px) 93vw, (max-width: 767px) 90vw, 575px"
          // srcSet="https://assets-global.website-files.com/61129bb2ecedf8658b4c6911/6112cd9d5b27385cbcd7218d_portfolio-1-featured-image-personal-x-template-p-1080.jpeg 1080w, https://assets-global.website-files.com/61129bb2ecedf8658b4c6911/6112cd9d5b27385cbcd7218d_portfolio-1-featured-image-personal-x-template.jpg 1150w"
          className="image portfolio-card"
          loading="eager"
        />
      </div>
      <div>
        <h3 className="title home-portfolio-card">{title}</h3>
        <p
          className="paragraph home-portfolio-card"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
        <div className="hc-flex read-case-study">
          <Link to={`/portfolio/${link}`} target="_blank">
            Read case study
          </Link>
          <GoArrowRight className="arrow-right" />
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
