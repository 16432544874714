import React from "react";
import "./Portfolio.css";

function PortfolioHero(props) {
  const {
    title,
    type,
    expertise,
    date,
    link,
    github,
    platforms,
    deliverables,
    heroImage,
  } = props;

  const getLink = () => {
    if (typeof link === "string") {
      return link.startsWith("http") ? (
        <a
          href={link}
          target="_blank"
          className="portfolio-project-website-link"
        >
          Visit website
        </a>
      ) : (
        link
      );
    }
    const list = [];
    for (const key in link) {
      if (link[key] !== "#")
        list.push(
          <>
            <a
              href={link[key]}
              target="_blank"
              className="portfolio-project-website-link"
            >
              {key}
            </a>
            {/* &nbsp;&nbsp; */}
          </>
        );
      else {
        list.push(<div>{key}</div>);
      }
    }
    return list;
  };

  return (
    <div className="section container-default portfolio-hero" data-aos="fade">
      <div className="top-company-date">
        <div className="title company-name">{type}</div>
        <div className="articles-news-content-bottom-divider"></div>
        <div>{date}</div>
      </div>
      <h1 className="title portfolio-title">{title}</h1>
      <div className="about-portfolio-project split">
        <div className="about-portfolio-project-item">
          <h2 className="title h4-size about-portfolio-project-title">
            Expertise
          </h2>
          <div>{expertise}</div>
        </div>
        <div className="about-portfolio-project-item">
          <h2 className="title h4-size about-portfolio-project-title">
            Platforms
          </h2>
          <div>{platforms}</div>
        </div>
        <div className="about-portfolio-project-item">
          <h2 className="title h4-size about-portfolio-project-title">
            Deliverables
          </h2>
          <div>{deliverables}</div>
        </div>
        <div className="about-portfolio-project-item">
          <h2 className="title h4-size about-portfolio-project-title">
            Website
          </h2>
          <div className="wrap-links">{getLink()}</div>
        </div>
      </div>
      <div>
        <img
          alt="na"
          src={heroImage}
          className="image portfolio-project-main-image"
        />
      </div>
    </div>
  );
}

export default PortfolioHero;
