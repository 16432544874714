import React, { useState } from "react";
import axios from "axios";
import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import "./Contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [formStatusMessage, setFormStatusMessage] = useState("");

  const handleSendMessage = () => {
    if (!name || !email || !subject || !message) {
      setFormStatusMessage("Please enter valid details.");
      return;
    }
    setFormStatusMessage("Submitting, please wait...");

    axios
      .post(
        "https://api.web3forms.com/submit",
        {
          name,
          email,
          phone,
          subject: "From Portfolio: " + subject,
          message,
          access_key: "15b6e519-e350-48ce-ab7b-e32490ec1b92",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setFormStatusMessage("Message sent successfully.");
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        setFormStatusMessage("Some error occurred. Please try again later.");
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <section className="container-medium-838px section" data-aos="fade">
        <h1 className="title text-center">Contact Me</h1>
        <p className="text-center">
          I am open to exploring new opportunities, please reach out.
        </p>

        <div className="contact-form _2-column-grid contact-form-grid">
          <div className="input-wrapper">
            <label className="field-label">Name</label>
            <input
              type="text"
              className="input w-input"
              maxLength={256}
              value={name}
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <label className="field-label">Email Address</label>
            <input
              type="text"
              className="input w-input"
              maxLength={256}
              value={email}
              placeholder="contact@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <label className="field-label">Phone</label>
            <input
              type="text"
              className="input w-input"
              maxLength={256}
              value={phone}
              placeholder="+91 987654XXXX"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <label className="field-label">Subject</label>
            <input
              type="text"
              className="input w-input"
              maxLength={256}
              value={subject}
              placeholder="Ex. Hiring for the role of SDE"
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="input-wrapper message">
            <label className="field-label">Message</label>
            <textarea
              className="text-area w-input"
              maxLength={5000}
              placeholder="Please write your message..."
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </div>
          <div>
            <p>{formStatusMessage}</p>
            <div className="btn btn-primary" onClick={handleSendMessage}>
              Send message
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default Contact;
